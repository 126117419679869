import React from 'react';

const WorkProcessFour = () => {
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div
        className="work-process-area bg-position-right pd-top-120 pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/16.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center pb-5">
                <h6 className="sub-title-sky-blue">WORK PROCESS</h6>
                <h2 className="title">
                  Empowering Your Business With Artificial
                </h2>
              </div>
            </div>
          </div>
          <div className="work-process-area-inner-2">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center">
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/36.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Assessment</h5>
                    <p className="content">
                      Research ipsum dolor sit consec tetur sed diam in the
                      aliquam tempor
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center">
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/37.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Implementationplanning</h5>
                    <p className="content">
                      Create ipsum dolor sit consec tetur sed diam in the
                      aliquam tempor
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center">
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/38.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Capabilities</h5>
                    <p className="content">
                      Develope ipsum dolor sit consec tetur sed diam in the
                      aliquam tempor
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="single-work-process-inner style-2 text-center">
                  <div className="thumb mb-3">
                    <img src="assets/img/icon/39.svg" alt="img" />
                  </div>
                  <div className="details">
                    <h5 className="mb-3">Innovation</h5>
                    <p className="content">
                      Shop ipsum dolor sit consec tetur Malesuada sed diam in
                      the
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessFour;
